import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { CreateCloudComponent } from '../dialogs/create-cloud/create-cloud.component';
import { SubscribeCloudComponent } from '../dialogs/subscribe-cloud/subscribe-cloud.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { DeleteCloudComponent } from '../dialogs/delete-cloud/delete-cloud.component';
import { MembersComponent } from '../dialogs/members/members.component';
import { MaskCloudComponent } from '../dialogs/mask-cloud/mask-cloud.component';
import { SharedCloudsComponent } from '../dialogs/shared-clouds/shared-clouds.component';
import { Subscription } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private dialog: MatDialog, private loginService: LoginService) {
        this.subscriptions.add(
            this.loginService.closeAllDialogs$.subscribe((data: boolean) => {
                if (data) {
                    this.closeAllDialogs();
                }
            })
        );
    }

    private subscriptions: Subscription = new Subscription();

    /**
     * @param {number} cloudId L'identifiant optionnel du cloud que l'utilisateur souhaite modifier
     * @param {number} groupId Les identifiants optionnels des groupes que l'utilisateur souhaite ajouter au cloud
     * @param {number} userId Les identifiants optionnels des utilisateurs que l'utilisateur souhaite ajouter au cloud
     * Ouvre la fenetre de création de cloud
     */
    openCreateCloudDialog(cloudId?: number, groupId?: number, userId?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '650px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (cloudId) {
            dialogConfig.data.cloudId = cloudId;
        }
        if (userId) {
            dialogConfig.data.userId = userId;
        }
        if (groupId) {
            dialogConfig.data.groupId = groupId;
        }

        const ref: MatDialogRef<CreateCloudComponent> = this.dialog.open(
            CreateCloudComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {number} cloudId Les identifiants optionnels des clouds auxquels l'utilisateur souhaite s'enregistrer
     * Ouvre la fenetre d'enregistrement de cloud
     */
    openSubscribeCloudDialog(cloudId?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '900px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (cloudId) {
            dialogConfig.data.cloudId = cloudId;
        }

        const ref: MatDialogRef<SubscribeCloudComponent> = this.dialog.open(
            SubscribeCloudComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {number} cloudId L'identifiant du cloud que l'utilisateur souhaite suprimer
     * Ouvre la fenetre d'erreur
     */
    openDeleteCloudDialog(cloudId: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (cloudId) {
            dialogConfig.data.cloudId = cloudId;
        }

        const ref: MatDialogRef<DeleteCloudComponent> = this.dialog.open(
            DeleteCloudComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {number} cloudId L'identifiant du cloud que l'utilisateur souhaite suprimer
     * Ouvre la fenetre d'erreur
     */
    openMembersDialog(cloudId: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (cloudId) {
            dialogConfig.data.cloudId = cloudId;
        }

        const ref: MatDialogRef<MembersComponent> = this.dialog.open(
            MembersComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {number} cloudId L'identifiant du cloud que l'utilisateur souhaite suprimer
     * Ouvre la fenetre d'erreur
     */
    openSharedCloudDialog(type: string, id: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (type) {
            dialogConfig.data.type = type;
        }
        if (id) {
            dialogConfig.data.id = id;
        }

        const ref: MatDialogRef<SharedCloudsComponent> = this.dialog.open(
            SharedCloudsComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });

        return ref.afterClosed();
    }

    /**
     * @param {string} content Le message d'erreur que l'on souhaite afficher
     * Ouvre la fenetre d'erreur
     */
    openErrorDialog(content?: string) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        this.dialog.open(ErrorComponent, dialogConfig);
    }

    /**
     * Ouvre la fenetre permettant de masquer un cloud
     */
    openMaskCloudDialog(cloudId: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (cloudId) {
            dialogConfig.data.cloudId = cloudId;
        }

        const ref: MatDialogRef<MaskCloudComponent> = this.dialog.open(
            MaskCloudComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * ferme toutes les modales ouvertes (utilisé par is_still_connected)
     */
    closeAllDialogs(): void {
        this.dialog.closeAll();
    }
}
