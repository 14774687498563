import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CloudService } from '../app/services/cloud.service';
import { LoginService } from '../app/services/login.service';
import { ConfigService } from '../app/services/config.service';
import { DialogService } from './services/dialog.service';

import { isMobile, isIE } from 'mobile-device-detect';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    subscriptions = new Subscription();

    mouseMoved: boolean;
    isPanelOpen = true;
    loading = true;

    constructor(
        private cloudService: CloudService,
        private loginService: LoginService,
        private configService: ConfigService,
        private dialogService: DialogService,

        private router: Router,
        private route: ActivatedRoute
    ) {}

    @HostListener('window:message', ['$event']) onMessage(event: any) {
        if (event.data.logout) {
            window.location.href = event.data.logout;
        } else if (event.data.structureid) {
            this.loginService.setLastStructure(event.data);
        } else if (event.data === 'getURL') {
            this.subscriptions.add(
                this.loginService.getCurrent().subscribe((data: any) => {
                    if (data === 0) {
                        window.location.href = this.configService.getRedirectEndPoint();
                    } else {
                        if (isMobile) {
                            if (this.loading) {
                                this.dialogService.openErrorDialog(
                                    "Ce site n'est pas optimisé pour un usage mobile, préférez une navigation sur ordinateur"
                                );
                            }
                        }
                        this.loading = false;
                    }
                })
            );
        } else if (event.data === 'refresh') {
            this.cloudService.refreshClouds();
        }
    }

    @HostListener('window:mousemove', ['$event']) onMouseMoved(event: any) {
        this.mouseMoved = true;
    }

    ngOnInit() {
        this.mouseMoved = false;
        this.pingGetCurrent();
        this.loginService.initCheckConnectedInterval();
        this.cloudService.clearCurrentCloud.subscribe(() => {
            this.router.navigate([]);
        });

        this.route.queryParams.subscribe((params) => {
            if (params['fileurl']) {
                this.router.navigate(['cloud', 0], { queryParamsHandling: 'merge' });
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    updateCurrentCloud(event: any) {
        this.router.navigate(['cloud', event.id]);
    }

    toggleNav() {
        this.isPanelOpen = !this.isPanelOpen;
    }

    pingGetCurrent() {
        setInterval(() => {
            if (this.mouseMoved) {
                this.subscriptions.add(
                    this.loginService.getCurrentUserCloud().subscribe((data: any) => {
                        this.mouseMoved = false;
                    })
                );
            }
        }, 1000 * 20 * 60);
    }

    isLoading() {
        return this.loading;
    }
}
