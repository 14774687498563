<div class="container" [ngClass]="{ unselected: !isCloudSelected() }">
    <div
        class="select-box"
        (click)="toggleSelected()"
        [ngClass]="{ 'icon-select': !isCloudSelected(), 'icon-selected': isCloudSelected() }"
    ></div>
    <div class="main-container">
        <div class="cloud-name">{{ cloud.name }}</div>
        <div class="author-container">
            <span class="icon" [ngClass]="getUserIcon(cloud.owner)"></span>
            <span class="author-name">{{ getAuthorName() }}</span>
        </div>
        <div class="members-count">{{ cloud.members_count - 1 }}</div>
    </div>
    <span
        class="icon-down"
        [ngClass]="{ opened: isCloudOpened() }"
        (click)="showCloud($event)"
    ></span>

    <div id="cloud-container" [@slideToggle]="openedCloud ? 'open' : 'closed'">
        <div class="name-user-container" *ngFor="let user of cloud.users">
            <span class="icon" [ngClass]="getUserIcon(user)"></span>
            <span class="name">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span>
        </div>
        <div class="name-group-container" *ngFor="let group of cloud.groups">
            <div class="group-header">
                <span class="icon-groupe"></span>
                <span class="name">{{ group.name }}</span>
            </div>
            <div class="users-container" *ngFor="let user of group.users">
                <span class="icon-apprenant"></span>
                <span class="name-user"
                    >{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span
                >
            </div>
        </div>
    </div>
</div>
