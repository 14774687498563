<div class="header">
    <span class="icon-easi-cloud-line"></span>
    <span class="label" [innerHTML]="headerText"></span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <ng-container *ngIf="isLoaded">
        <div class="content-header">
            <span class="clouds-count"
                ><b>{{ clouds?.length }}</b> espaces</span
            >
        </div>
        <div class="top">
            <span class="name">Nom</span>
            <span class="author">Auteur</span>
            <span class="members">Inscrits</span>
        </div>
        <div class="cloud-container">
            <app-shared-clouds-entry
                *ngFor="let cloud of clouds"
                [cloud]="cloud"
                (click)="openSharedCloud(cloud)"
            ></app-shared-clouds-entry>
            <div class="no-shared-clouds" *ngIf="clouds.length === 0">
                Aucun cloud partagé avec
                {{ data.type === 'group' ? 'ce groupe' : 'cet utilisateur' }}
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoaded">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </ng-container>
</mat-dialog-content>
<div class="footer">
    <div class="button" (click)="openCreateCloud()">Créer un nouvel espace</div>
</div>
