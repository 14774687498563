<div class="main" [ngClass]="{ hidden: reduced }">
    <div class="header">
        <div class="clouds-count" (click)="selectCloud(root)">
            <span class="icon-local"></span>
            <span class="label"
                ><b>{{ totalCloudsCount }}</b> clouds</span
            >
        </div>
        <div class="right-container">
            <div class="search-container">
                <div
                    class="search-input-container"
                    [ngClass]="{ active: activeSearch, empty: isSearchEmpty() }"
                >
                    <input
                        class="library-search-field"
                        placeholder="Chercher..."
                        [(ngModel)]="search"
                        (keyup.enter)="refreshDataFromSearch()"
                        #searchElement
                    />
                    <span class="icon-Fermerdetails" (mousedown)="resetSearch($event)"></span>
                    <span
                        class="icon-search"
                        [ngClass]="{ blue: !isSearchEmpty() }"
                        (click)="refreshDataFromSearch()"
                        *ngIf="activeSearch"
                    ></span>
                    <span
                        class="icon-search"
                        [ngClass]="{ blue: !isSearchEmpty() }"
                        (click)="toggleActiveSearch()"
                        *ngIf="!activeSearch"
                    ></span>
                </div>
            </div>
            <div
                class="icon-minimizebloc"
                [ngClass]="{ inversed: reduced }"
                (click)="toggleReduce()"
            ></div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="container">
        <div class="started-clouds" *ngIf="showCreateButton()">
            <div class="title" (click)="toggleAuthorClouds()">
                <span class="icon-partage-avec-autres"></span>
                <span class="label">Mes espaces ({{ startedCloudsCount }})</span>
                <span
                    class="icon-down"
                    *ngIf="authorsClouds?.length > 0"
                    [ngClass]="{ open: !isClosedAuthorClouds }"
                ></span>
            </div>
            <div
                id="author-clouds-container"
                [@slideToggle]="isClosedAuthorClouds ? 'closed' : 'open'"
            >
                <app-nav-entry
                    *ngFor="let cloud of authorsClouds"
                    [cloud]="cloud"
                    (click)="selectCloud(cloud)"
                    [selectedCloud]="selectedCloud?.id"
                    [type]="'author'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second" *ngIf="showCreateButton()"></div>
        <div class="member-clouds">
            <div class="title" (click)="toggleMemberClouds()">
                <span class="icon-partage-avec-moi"></span>
                <span class="label">Autres espaces ({{ memberCloudsCount }})</span>
                <span
                    class="icon-down"
                    *ngIf="membersClouds?.length > 0"
                    [ngClass]="{ open: !isClosedMemberClouds }"
                ></span>
            </div>
            <div
                id="member-clouds-container"
                [@slideToggle]="isClosedMemberClouds ? 'closed' : 'open'"
            >
                <app-nav-entry
                    *ngFor="let cloud of membersClouds"
                    [cloud]="cloud"
                    (click)="selectCloud(cloud)"
                    [selectedCloud]="selectedCloud?.id"
                    [type]="'member'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second"></div>
        <div class="deleted-clouds">
            <div class="title" (click)="selectCloud(bin)">
                <span class="icon-bin"></span>
                <span class="label">Élements supprimés</span>
            </div>
            <div class="quota-warning" *ngIf="showQuotaWarning">
                <span
                    class="icon-Aide"
                    [tooltip]="getQuotaWarningMessage()"
                    tooltipPosition="right"
                    tooltipColor="#e8b600"
                ></span>
            </div>
        </div>
        <div class="separator second"></div>
        <div class="masked-clouds">
            <div class="title" (click)="toggleMaskedClouds()">
                <span class="icon-masquer"></span>
                <span class="label">Espaces masqués ({{ maskedClouds?.length }})</span>
                <span
                    class="icon-down"
                    *ngIf="maskedClouds?.length > 0"
                    [ngClass]="{ open: !isClosedMaskedClouds }"
                ></span>
            </div>
            <div
                id="masked-clouds-container"
                [@slideToggle]="isClosedMaskedClouds ? 'closed' : 'open'"
            >
                <app-nav-entry
                    *ngFor="let cloud of maskedClouds"
                    [cloud]="cloud"
                    (click)="selectCloud(cloud)"
                    [selectedCloud]="selectedCloud?.id"
                    [type]="'masked'"
                ></app-nav-entry>
            </div>
        </div>
    </div>

    <div class="separator"></div>
    <div class="subscribe-cloud">
        <div
            class="button-subscribe"
            *ngIf="showSubscribeButton()"
            (click)="openSubscribeCloudDialog()"
        >
            M'inscrire à un espace
        </div>
    </div>
    <!-- <div
        class="add-cloud"
        *ngIf="showCreateButton() && userLoaded()"
        (click)="openCreateCloudDialog()"
    >
        <span class="icon-plus"></span>
    </div> -->
</div>
