import { Injectable } from '@angular/core';

/**
 * Service gérant la configuration de la plateforme
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config: any;

    setConfig(config: any) {
        this.config = config;
    }

    getConfig(): any {
        return this.config;
    }

    getEndPoint(): string {
        return this.config.endpoint.cloud.backend;
    }

    getEasiSharedEndPoint(): string {
        return this.config.endpoint.shared.front;
    }

    getRedirectEndPoint(): string {
        return this.config.endpoint.cloud.redirect;
    }

    getIframeLogoutEndPoint() {
        return this.config.endpoint.cloud.iframe_logout;
    }
}
