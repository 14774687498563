<div class="container">
    <div class="icon" [ngClass]="getIconStyle()"></div>
    <div class="name">{{ getElementName() }}</div>
    <div class="role" *ngIf="getIconStyle()">
        <span
            class="icon icon-droit-lecture"
            *ngIf="rights === 1"
            tooltip="Lecture seule"
            tooltipPosition="left"
            tooltipColor="#e8b600"
        ></span>
        <span
            class="icon icon-droit-ecriture"
            *ngIf="rights === 7"
            tooltip="Lecture / Ecriture"
            tooltipPosition="left"
            tooltipColor="#e8b600"
        ></span>
        <span
            class="icon icon-droit-moderation"
            *ngIf="rights >= 15"
            tooltip="Modération"
            tooltipPosition="left"
            tooltipColor="#e8b600"
        ></span>
    </div>
    <div
        class="icon-down"
        [ngClass]="{ opened: isGroupOpened() }"
        *ngIf="type === 'group'"
        (click)="showGroup()"
    ></div>
    <div
        class="group-container"
        *ngIf="type === 'group'"
        [@slideToggle]="openedGroup ? 'open' : 'closed'"
    >
        <div class="name-container" *ngFor="let user of element.users">
            <span [ngClass]="getUserIconStyle(user)"></span>
            <span class="name">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span>
        </div>
    </div>
</div>
