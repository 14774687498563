<div class="container" [ngClass]="{ selected: isCloudSelected() }">
    <div class="icon-easi-cloud-line"></div>
    <div class="title">{{ cloud.name }}</div>

    <div
        class="members"
        [ngClass]="{ red: isOnlyMember() }"
        tooltip="Participants"
        tooltipPosition="left"
        tooltipColor="#e8b600"
        (click)="openMembersDialog($event)"
    >
        ({{ cloud.members }})
    </div>

    <div class="icons">
        <span
            [ngClass]="getCloudNotificationsIcon()"
            [tooltip]="getNotificationsIconTooltip()"
            tooltipPosition="left"
            tooltipColor="#e8b600"
            (click)="toggleCloudNotifications($event, cloud)"
        ></span>
        <span
            class="icon-Editer"
            *ngIf="showIconEdit()"
            tooltip="Modifier l'espace"
            tooltipPosition="left"
            tooltipColor="#e8b600"
            (click)="openEditCloudDialog($event)"
        ></span>
        <span
            class="icon-bin"
            *ngIf="showIcons()"
            tooltip="Supprimer l'espace"
            tooltipPosition="left"
            tooltipColor="#e8b600"
            (click)="openDeleteCloudDialog($event)"
        ></span>
        <span
            class="icon-masquer"
            *ngIf="!isMaskedCloud()"
            tooltip="Masquer l'espace"
            tooltipPosition="left"
            tooltipColor="#e8b600"
            (click)="openMaskCloudDialog($event)"
        ></span>
        <span
            class="icon-voir"
            *ngIf="isMaskedCloud()"
            tooltip="Réafficher l'espace"
            tooltipPosition="left"
            tooltipColor="#e8b600"
            (click)="unmaskCloud($event)"
        ></span>
    </div>
</div>
