import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { DialogService } from '../../services/dialog.service';
import { CloudService } from '../../services/cloud.service';
import { UsersService } from '../../services/users.service';
import { LoginService } from '../../services/login.service';
import { Subscription } from 'rxjs';
import { Cloud } from 'src/app/structures/cloud';
import { slideToggle } from 'src/app/animations/slide-toggle.animation';

/**
 * Composant gérant l'affichage du menu de gauche
 */
@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    animations: [slideToggle]
})
export class NavComponent implements OnInit, OnDestroy {
    /**
     * @param {EventEmitter<any>} select emet un evenement avec le cloud a l'interieur pour signaler qu'il a ete selectionne.
     */
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() resize: EventEmitter<boolean> = new EventEmitter();

    search: string;
    activeSearch: boolean;
    @ViewChild('searchElement') searchElement: ElementRef;

    getClouds$: Subscription;
    routeParams$: Subscription;
    getUserQuota$: Subscription;
    refresh$: Subscription;
    refreshSingleCloud$: Subscription;

    /**
     * @param {Array<Object>} authorsClouds le tableau des cloud dont l'utilisateur est l'auteur.
     * @param {Array<Object>} membersClouds le tableau des cloud dont l'utilisateur est membre.
     * @param {any} selectedCloud indique le cloud selectionne.
     * @param {number} startedCloudsCount le nombre de cloud dont l'utisateur est l'auteur.
     * @param {number} memberCloudsCount le nombre de cloud dont l'utisateur est memnbre.
     * @param {number} totalCloudsCount le nombre de cloud total de l'utilisateur.
     * @param {boolean} showQuotaWarning montrer ou non l'avertissement de taille encore disponible.
     * @param {number} quota la taille maximale d'une corbeille utulisee pour les calculs.
     * @param {boolean} isClosedAuthorClouds si le panneau dont l'user est l'auteur est ferme ou non.
     * @param {boolean} isClosedMemberClouds si le panneau dont l'user est membre est ferme ou non.
     */
    authorsClouds: Array<Cloud>;
    membersClouds: Array<Cloud>;
    maskedClouds: Array<Cloud>;

    selectedCloud: any;

    startedCloudsCount: number;
    memberCloudsCount: number;
    totalCloudsCount: number;

    root: any;
    bin: any;

    showQuotaWarning: boolean;
    quota: number;

    isClosedAuthorClouds = false;
    isClosedMemberClouds = false;
    isClosedMaskedClouds = false;

    reduced = false;

    constructor(
        private dialogService: DialogService,
        private cloudService: CloudService,
        private loginService: LoginService,
        private usersService: UsersService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    /**
     * Rafraichit la liste des clouds de l'utilisateur
     */
    refreshData() {
        this.getClouds$ = this.cloudService.getClouds(this.search).subscribe(
            (data: any) => {
                this.authorsClouds = data.mine;
                this.startedCloudsCount = data.mine.length;

                this.membersClouds = data.member;
                this.memberCloudsCount = data.member.length;

                this.maskedClouds = data.hidden;

                this.totalCloudsCount = this.memberCloudsCount + this.startedCloudsCount;

                this.root = {
                    id: 0,
                    url: data.root
                };

                this.bin = {
                    id: -1,
                    url: data.root + '&view=trashbin'
                };
            },
            (error: HttpErrorResponse) => {
                if (error.error.errorCode !== 'USER_NOT_AUTH') {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            }
        );
    }

    /**
     * Déplie/replie la liste des clouds dont l'utilisateur est auteur
     */
    toggleAuthorClouds() {
        this.isClosedAuthorClouds = !this.isClosedAuthorClouds;
        if (!this.isClosedAuthorClouds && !this.isClosedMemberClouds) {
            this.toggleMemberClouds();
        }
        if (!this.isClosedAuthorClouds && !this.isClosedMaskedClouds) {
            this.toggleMaskedClouds();
        }
    }

    /**
     * Déplie/replie la liste des clouds dont l'utilisateur est membre
     */
    toggleMemberClouds() {
        this.isClosedMemberClouds = !this.isClosedMemberClouds;
        if (!this.isClosedMemberClouds) {
            this.cloudService.refreshClouds();
        }
        if (!this.isClosedMemberClouds && !this.isClosedAuthorClouds) {
            this.toggleAuthorClouds();
        }
        if (!this.isClosedMemberClouds && !this.isClosedMaskedClouds) {
            this.toggleMaskedClouds();
        }
    }

    toggleMaskedClouds() {
        this.isClosedMaskedClouds = !this.isClosedMaskedClouds;
        if (!this.isClosedMaskedClouds && !this.isClosedAuthorClouds) {
            this.toggleAuthorClouds();
        }
        if (!this.isClosedMaskedClouds && !this.isClosedMemberClouds) {
            this.toggleMemberClouds();
        }
    }

    toggleReduce() {
        this.reduced = !this.reduced;
        this.resize.emit(this.reduced);
    }

    /**
     * Sélectionne un cloud à afficher dans la vue principal
     */
    selectCloud(cloud: any) {
        this.selectedCloud = cloud;
        this.select.emit(cloud);
    }

    /**
     * Ouvre la fenêtre de création de cloud
     */
    openCreateCloudDialog() {
        this.dialogService.openCreateCloudDialog();
    }

    /**
     * Ouvre la fenêtre d'enregistrement à un cloud
     */
    openSubscribeCloudDialog() {
        this.dialogService.openSubscribeCloudDialog();
    }

    /**
     * Indique si la bouton permettant d'ouvrir la fenetre de création de cloud doit être affiché
     * @returns {Boolean} True si le bouton doit être affiché, faux dans le cas contraire
     */
    showCreateButton(): boolean {
        return !this.loginService.isLearner();
    }

    /**
     * Indique si la bouton permettant d'ouvrir la fenetre d'enregistrement à un cloud doit être affiché
     * @returns {Boolean} True si le bouton doit être affiché, faux dans le cas contraire
     */
    showSubscribeButton(): boolean {
        return this.loginService.isAdmin();
    }

    /**
     * Obtenir la taille maximale de la corbeille.
     */
    getQuotaWarningMessage(): string {
        let displayQuota: string;

        if (this.quota > 1024 * 1024 * 1024) {
            displayQuota = Math.trunc(this.quota / 1024 / 1024 / 1024 / 2) + ' Go';
        } else if (this.quota > 1024 * 1024) {
            displayQuota = Math.trunc(this.quota / 1024 / 1024 / 2) + ' Mo';
        } else if (this.quota > 1024) {
            displayQuota = Math.trunc(this.quota / 1024 / 2) + ' Ko';
        } else {
            displayQuota = Math.trunc(this.quota) / 2 + ' o';
        }
        return (
            'Les fichiers de plus de 30 jours sont supprimés automatiquement. Si votre corbeille dépasse ' +
            displayQuota +
            ', les fichiers les plus anciens sont effacés automatiquement quelle que soit leur ancienneté'
        );
    }

    toggleActiveSearch() {
        if (!this.activeSearch) {
            this.activeSearch = true;
            setTimeout(() => {
                this.searchElement.nativeElement.focus();
            });
        } else {
            setTimeout(() => {
                this.activeSearch = false;
            }, 100);
        }
    }

    isSearchEmpty() {
        if (this.search) {
            return this.search.length === 0;
        }
        return true;
    }

    refreshDataFromSearch() {
        this.refreshData();
        if (this.activeSearch && !this.search) {
            this.toggleActiveSearch();
        }
    }

    resetSearch($event: Event): void {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.search = '';
        this.activeSearch = false;
        this.refreshData();
    }

    ngOnInit() {
        this.routeParams$ = this.route.queryParams.subscribe((params) => {
            if (params['new']) {
                if (params['user']) {
                    this.dialogService.openCreateCloudDialog(null, null, +params['user']);
                } else if (params['group']) {
                    this.dialogService.openCreateCloudDialog(null, +params['group'], null);
                } else {
                    this.dialogService.openCreateCloudDialog();
                }
            } else if (params['subscribe']) {
                if (params['cloud']) {
                    this.dialogService.openSubscribeCloudDialog(+params['cloud']);
                }
            } else if (params['shared']) {
                if (params['type'] && params['id']) {
                    this.dialogService
                        .openSharedCloudDialog(params['type'], params['id'])
                        .subscribe((receivedCloud: Cloud | undefined) => {
                            if (receivedCloud) {
                                this.authorsClouds.map((cloud) => {
                                    if (cloud.id === receivedCloud.id) {
                                        this.selectCloud(cloud);
                                    }
                                });
                                this.membersClouds.map((cloud) => {
                                    if (cloud.id === receivedCloud.id) {
                                        this.selectCloud(cloud);
                                    }
                                });
                                this.maskedClouds.map((cloud) => {
                                    if (cloud.id === receivedCloud.id) {
                                        this.selectCloud(cloud);
                                    }
                                });
                            }
                        });
                }
            }
        });

        this.search = '';
        this.totalCloudsCount = 0;
        this.refreshData();
        this.toggleMemberClouds();
        this.toggleMaskedClouds();

        this.getUserQuota$ = this.usersService.getUserQuota().subscribe((data: any) => {
            this.quota = data.quota.quota;
            if (data.quota.relative >= 50) {
                this.showQuotaWarning = true;
            } else {
                this.showQuotaWarning = true;
            }
        });

        this.refresh$ = this.cloudService.refresh.subscribe(
            () => {
                this.refreshData();
                if (this.isClosedAuthorClouds && this.isClosedMemberClouds) {
                    this.toggleAuthorClouds();
                }
            },
            (error: HttpErrorResponse) => {
                this.dialogService.openErrorDialog(error.error.userMessage);
            }
        );

        this.refreshSingleCloud$ = this.cloudService.refreshSingleCloud.subscribe(
            (cloudId: number) => {
                if (this.selectedCloud && this.selectedCloud.id === cloudId) {
                    this.select.emit(this.selectedCloud);
                }
            }
        );
    }

    userLoaded() {
        return !!this.loginService.getUser();
    }

    ngOnDestroy() {
        if (this.getClouds$) {
            this.getClouds$.unsubscribe();
        }
        if (this.routeParams$) {
            this.routeParams$.unsubscribe();
        }
        if (this.getUserQuota$) {
            this.getUserQuota$.unsubscribe();
        }
        if (this.refresh$) {
            this.refresh$.unsubscribe();
        }
        if (this.refreshSingleCloud$) {
            this.refreshSingleCloud$.unsubscribe();
        }
    }
}
