import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { CloudService } from '../../services/cloud.service';
import { UsersService } from '../../services/users.service';
import { LoginService } from '../../services/login.service';
import { Subscription } from 'rxjs';

/**
 * Composant gérant l'affichage de la fenêtre d'enregistrement à un cloud
 */
@Component({
    selector: 'app-subscribe-cloud',
    templateUrl: './subscribe-cloud.component.html',
    styleUrls: ['./subscribe-cloud.component.scss']
})
export class SubscribeCloudComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<SubscribeCloudComponent>,
        private cloudService: CloudService,
        private usersService: UsersService,
        private loginService: LoginService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    getAdminClouds$: Subscription;
    getAdminCloudsCount$: Subscription;
    subscribeClouds$: Subscription;
    getCloud$: Subscription;
    getStructures$: Subscription;
    updateStructure$: Subscription;

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    LIMIT = 30;

    clouds: any;
    cloudsCount: number;
    currentPage: number;

    creationDone: boolean;

    structures: Array<any>;
    selectedStructure: any;

    subscribedClouds: Array<number>;

    searchTerm: string;

    /**
     * Indique si le menu de sélection de structure doit s'afficher pour l'utilisateur actuel
     * @returns {Boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    showStructuresDropdown(): boolean {
        return this.loginService.isNationalAdmin();
    }

    /**
     * Rafraichit la liste des clouds
     */
    nextPage() {
        const params: any = {};
        this.currentPage++;

        params.structureid = this.selectedStructure.id;
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPage * this.LIMIT;
        params.limit = this.LIMIT;

        if (this.selectedStructure.id !== undefined) {
            this.getAdminClouds$ = this.cloudService.getAdminClouds(params).subscribe(
                (data: any) => {
                    this.clouds = this.clouds.concat(data);
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
        }
    }

    updateStructure($event: any) {
        this.structures = $event;
        for (const i in this.structures) {
            if (this.structures[i].selected) {
                this.selectedStructure = {
                    id: this.structures[i].key,
                    name: this.structures[i].title
                };
            }
        }
        this.refreshData();
    }

    /**
     * rafraichit les donnees lors d'une recherche, scroll de bas de page ou changement de seclectedStructure.
     */
    refreshData() {
        if (this.selectedStructure) {
            if (this.selectedStructure.id !== undefined && this.selectedStructure.id !== 0) {
                this.loginService.updateLastStructure(this.selectedStructure);
            }
            this.clouds = [];
            this.currentPage = -1;
            this.nextPage();
            this.getAdminCloudsNumber();
        }
    }

    /**
     * @param {any} data Un objet contenant les champ suivants : id, selected
     * Ajoute/supprime un cloud à la liste des clouds sélectionnés
     */
    addToSubscribedCloud(data: any) {
        if (data.selected) {
            this.subscribedClouds.push(data.id);
        } else {
            for (const i in this.subscribedClouds) {
                if (this.subscribedClouds[i] === data.id) {
                    this.subscribedClouds.splice(parseInt(i, 10), 1);
                }
            }
        }
    }

    /**
     * @param {any} cloud Un objet représentant un cloud
     * Indique si le cloud passé en paramètre est sélectionné ou non
     * @returns {Boolean} True si le cloud est sélectionné, false dans le cas contraire
     */
    isCloudSelected(cloud: any): boolean {
        for (const i in this.subscribedClouds) {
            if (this.subscribedClouds[i] === cloud.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Initialise le nombre de clouds auxquels l'administrateur peut s'inscrire
     */
    getAdminCloudsNumber() {
        const params: any = {};

        params.structureid = this.selectedStructure.id;

        if (params.structureid !== undefined) {
            this.getAdminCloudsCount$ = this.cloudService.getAdminCloudsCount(params).subscribe(
                (data: any) => {
                    this.cloudsCount = data.count;
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
        }
    }

    /**
     * Indique si le bouton de validation de l'inscription doit être activé ou non
     * @returns {Boolean} True si le bouton d'inscription doit etre activé, false dans le cas contraire
     */
    canSubscribeToCloud(): boolean {
        return this.subscribedClouds.length > 0;
    }

    /**
     * S'enregistre aux clouds présent dans la liste des clouds sélectionnés
     */
    subscribeToClouds() {
        if (this.canSubscribeToCloud()) {
            this.creationDone = true;

            const body = {
                reservations: this.subscribedClouds
            };
            this.subscribeClouds$ = this.cloudService.subscribeClouds(body).subscribe(
                (data: any) => {
                    this.cloudService.refreshClouds();
                    this.closeDialog();
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
        }
    }

    /**
     * Ferme la fenêtre d'enregistrement à un cloud
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * Initialise les clouds sélectionnés selon les élements passé en paramètre
     */
    initRoutes() {
        if (this.data.cloudId) {
            this.getCloud$ = this.cloudService.getCloud(this.data.cloudId).subscribe(
                () => {
                    this.addToSubscribedCloud({ selected: true, id: this.data.cloudId });
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
        }
    }

    ngOnInit() {
        this.clouds = [];
        this.creationDone = false;
        this.subscribedClouds = [];
        this.currentPage = -1;

        this.getStructures$ = this.usersService.getStructures().subscribe(
            (data) => {
                this.structures = data.data.structures.map((structure: any) => {
                    return {
                        key: structure.id,
                        title: structure.name
                    };
                });
                this.structures.unshift({ title: 'Administration nationale', key: 0 });
                if (this.loginService.isNationalAdmin()) {
                    this.selectedStructure = this.loginService.getLastStructure();
                } else {
                    this.selectedStructure = {
                        id: this.loginService.getUser().structureid,
                        name: this.loginService.getUser().localStructure
                    };
                }
                this.refreshData();
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.emit(error.error.userMessage);
            }
        );

        this.initRoutes();

        this.updateStructure$ = this.loginService.updateStructure.subscribe((data) => {
            this.selectedStructure = data;
        });
    }

    ngOnDestroy() {
        if (this.getAdminClouds$) {
            this.getAdminClouds$.unsubscribe();
        }
        if (this.getAdminCloudsCount$) {
            this.getAdminCloudsCount$.unsubscribe();
        }
        if (this.subscribeClouds$) {
            this.subscribeClouds$.unsubscribe();
        }
        if (this.getCloud$) {
            this.getCloud$.unsubscribe();
        }
        if (this.getStructures$) {
            this.getStructures$.unsubscribe();
        }
        if (this.updateStructure$) {
            this.updateStructure$.unsubscribe();
        }
    }
}
