import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { UsersService } from '../../../services/users.service';
import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';

import { Subscription } from 'rxjs';
import { slideToggle } from 'src/app/animations/slide-toggle.animation';

/**
 * Composant gérant l'affichage des utilisateurs/groupes dans la fenêtre de création de cloud
 */
@Component({
    selector: 'app-create-cloud-entry',
    templateUrl: './create-cloud-entry.component.html',
    styleUrls: ['./create-cloud-entry.component.scss'],
    animations: [slideToggle]
})
export class CreateCloudEntryComponent implements OnInit, OnDestroy {
    @Input() element: any;

    @Input() role: string;
    @Output() roleChange = new EventEmitter();

    @Input() selected: boolean;
    @Output() selectedChange = new EventEmitter();

    getGroup$: Subscription;

    openedGroup: boolean;
    type: string;

    structures: Array<any>;
    selectedStructure: any;
    structureOpened: boolean;
    structureSearch: string;

    constructor(
        private usersService: UsersService,
        private dialogService: DialogService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.openedGroup = false;

        if (this.element.shortname && this.element.name) {
            this.type = 'role';
        } else if (this.element.name && !this.element.shortname) {
            this.type = 'group';
        } else {
            this.type = 'user';
        }

        if (this.role === undefined) {
            this.role = 'reader';
            setTimeout(() => {
                this.roleChange.emit(this.role);
            });
        }
        if (this.type === 'role') {
            if (this.loginService.getUser().roles.localAdmin && !this.element.structureid) {
                this.element.structureid = [this.loginService.getUser().structureid];
            }
            this.structures = this.usersService
                .getStructuresList()
                .map((structure) => ({ key: structure.id, title: structure.name }));
            for (const i in this.structures) {
                if (this.structures[i].id === 0) {
                    this.structures.splice(parseInt(i, 10), 1);
                }
            }
            if (this.element.structureid === undefined) {
                this.element.structureid = [];
            }
        }
    }

    ngOnDestroy() {
        if (this.getGroup$) {
            this.getGroup$.unsubscribe();
        }
    }

    /**
     * Indique l'icône à afficher selon le type et le rôle de l'element actuel
     * @returns {string} La classe CSS correspondante à l'icône à afficher
     */
    getIconStyle(): string {
        if (this.type === 'role') {
            if (this.element.shortname === 'nationalAdmin') {
                return 'icon-adminnational';
            } else if (this.element.shortname === 'localAdmin') {
                return 'icon-adminlocal';
            } else if (this.element.shortname === 'internalTeacher') {
                return 'icon-formateurinterne';
            } else if (this.element.shortname === 'accountManager') {
                return 'icon-gestionnairedecomptes';
            } else if (this.element.shortname === 'contentManager') {
                return 'icon-gestionnairecontenuspayants';
            } else if (this.element.shortname === 'validator') {
                return 'icon-valideur';
            } else if (this.element.shortname === 'externalTeacher') {
                return 'icon-formateurexterne';
            } else if (this.element.shortname === 'corporationTeacher') {
                return 'icon-FormateurEntreprise';
            } else if (this.element.shortname === 'siteTeacher') {
                return 'icon-formateur-site';
            } else if (this.element.shortname === 'tutor') {
                return 'icon-tuteurentreprise';
            } else if (this.element.shortname === 'learner') {
                return 'icon-apprenant';
            }
        } else if (this.type === 'user') {
            return this.getUserIcon(this.element);
        } else if (this.type === 'group') {
            return 'icon-groupe';
        }
    }

    getUserIcon(user) {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    updateStructure($event: any) {
        this.structures = $event;
        this.element.structureid = [
            ...this.structures
                .filter((structure) => structure.selected)
                .map((structure) => structure.key)
        ];
        setTimeout(() => {
            for (const i in this.structures) {
                if (this.structures[i].selected) {
                    this.selectedStructure = {
                        id: this.structures[i].key,
                        name: this.structures[i].title
                    };
                }
            }
        });
    }

    /**
     * Renvoie le nom à afficher pour l'élement actuel
     * @returns {string} Renvoie le nom du group si l'élement est un groupe, ou le prénom et le nom si l'élement est un utilisateur
     */
    getElementName(): string {
        if (this.type === 'group' || this.type === 'role') {
            return this.element.name;
        } else {
            return this.element.lastname.toUpperCase() + ' ' + this.element.firstname;
        }
    }

    /**
     * Indique si l'élement actuel possède le rôle de modérateur
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire
     */
    isReader(): boolean {
        return this.role === 'reader';
    }

    /**
     * Indique si l'élement actuel possède le rôle de modérateur
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire
     */
    isWriter(): boolean {
        return this.role === 'writer';
    }

    /**
     * Indique si l'élement actuel possède le rôle de modérateur
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire
     */
    isModerator(): boolean {
        return this.role === 'moderator';
    }

    /**
     * Modifie le rôle de l'utilisateur dans le cloud
     * @param {boolean} boolean Un booléen indiquant si l'utilisateur doit avoir les rôles de modérateur
     */
    toggleRole() {
        if (this.role === 'reader') {
            this.role = 'writer';
        } else if (this.role === 'writer') {
            this.role = 'moderator';
        } else if (this.role === 'moderator') {
            this.role = 'reader';
        }
        this.roleChange.emit(this.role);
    }

    /**
     * Sélectionne/déselectionne l'element actuel
     */
    toggleSelected() {
        this.selected = !this.selected;
        this.selectedChange.emit(this.selected);
    }

    /**
     * Indique si l'élement actuel est sélectionné ou non
     */
    isElementSelected(): boolean {
        return this.selected;
    }

    /**
     * Indique si le groupe est déplié
     */
    isGroupOpened() {
        return this.openedGroup;
    }

    /**
     * Déplie le groupe sélectionné
     */
    showGroup(): void {
        if (this.element.users === undefined) {
            this.getGroup$ = this.usersService.getGroup(this.element.id).subscribe(
                (data: any) => {
                    this.element = data;
                    this.openedGroup = !this.openedGroup;
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            );
        } else {
            this.openedGroup = !this.openedGroup;
        }
    }

    canShowStructure() {
        return (
            this.type === 'role' &&
            this.loginService.getUser().roles.nationalAdmin &&
            this.element.shortname !== 'nationalAdmin'
        );
    }

    getStructureLabel() {
        if (this.element.structureid.length === 0) {
            return 'Structure';
        } else if (this.element.structureid.length === this.structures.length) {
            return 'Toutes les structures';
        } else {
            return this.element.structureid.length + ' structure(s)';
        }
    }

    isStructureOpened() {
        return this.structureOpened;
    }

    openStructure() {
        this.structureOpened = !this.structureOpened;
    }

    toggleStructureSelected(structure: number) {
        if (this.element.structureid.indexOf(structure) === -1) {
            this.element.structureid.push(structure);
        } else {
            this.element.structureid.splice(this.element.structureid.indexOf(structure), 1);
        }
    }

    isStructureSelected(structure: number): boolean {
        return this.element.structureid.indexOf(structure) !== -1;
    }

    getStructuresSelectedCount(): number {
        return this.element.structureid.length;
    }

    getStructuresCount(): number {
        return this.structures.length;
    }

    getSelectAllLabel(): string {
        if (this.element.structureid.length === this.structures.length) {
            return 'Tout désélectionner';
        } else {
            return 'Tout sélectionner';
        }
    }

    toggleSelectAll() {
        if (this.element.structureid.length === this.structures.length) {
            this.element.structureid = [];
        } else {
            this.element.structureid = [];
            for (const i in this.structures) {
                if (this.structures[i].id) {
                    this.element.structureid.push(this.structures[i].id);
                }
            }
        }
    }
}
