import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FlashMessageService } from './flash-message.service';
import { Cloud } from '../structures/cloud';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux cloud
 */
@Injectable({
    providedIn: 'root'
})
export class CloudService {
    constructor(private http: HttpClient, private flashMessageService: FlashMessageService) {}

    currentCloud: Cloud;

    refresh: Subject<any> = new Subject();
    refreshSingleCloud: Subject<number> = new Subject();
    resetCurrentCloud: Subject<number> = new Subject();
    cloudsSubject: BehaviorSubject<any> = new BehaviorSubject([]);
    updateCurrentCloud: Subject<Cloud> = new Subject();
    clearCurrentCloud: Subject<number> = new Subject();

    /**
     * @param {number} id L'identifiant du cloud à récuperer
     * Récupère les informations d'un cloud spécifique
     * @returns {Observable} Un observable du cloud récupéré
     */
    @log() getCloud(cloudId: number): Observable<any> {
        return this.http.get('/reservations/' + cloudId);
    }

    /**
     * Récupère la liste des clouds accessibles par l'utilisateur actuel
     * @returns {Observable} Un observable de la liste des clouds de l'utilisateur courant
     */
    @log() getClouds(search: string): Observable<any> {
        return this.http
            .get('/reservations/list', { params: { search } })
            .pipe(tap(this.cloudsSubject));
    }

    /**
     * retourne la liste des clouds de l'utilisateur connecté sous forme d'observable
     * @returns {Observable} Observable d'un array de clouds
     */
    @log() getCloudsSubject(): Observable<any> {
        return this.cloudsSubject.asObservable();
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : structureid et search
     * Récupère la liste des clouds auxquels l'administrateur peut s'inscrire
     * @returns {Observable} Un observable de la liste des clouds correspondant aux paramètres
     */
    @log() getAdminClouds(params: any): Observable<any> {
        return this.http.get('/reservations/admin', { params });
    }

    /**
     * @param {any} params Un objet contenant 1 paramètre : structureid
     * Récupère le nombre de clouds auxquels l'administrateur peut s'inscrire
     * @returns {Observable} Un observable du nombre de clouds correspondant aux paramètres
     */
    @log() getAdminCloudsCount(params: any): Observable<any> {
        return this.http.get('/reservations/admin?count=true', { params });
    }

    /**
     * Récupère la liste des clouds accessibles par l'utilisateur actuel
     * @returns {Observable} Un observable de la liste des clouds de l'utilisateur courant
     */
    @log() getSharedClouds(params: any): Observable<any> {
        return this.http.get('/reservations/shared', { params });
    }

    /**
     * @param {any} body Un object contenant 1 paramètre : Un tableau regroupant la liste des id des clouds auxquels on souhaite s'inscrire
     * Inscrit l'administrateur courant à un ou plusieurs clouds
     * @returns {Observable} Un observable d'un booléen si l'inscription aux clouds s'est déroulé correctement
     */
    @log() subscribeClouds(body: any): Observable<any> {
        return this.http.post('/reservations/register', body);
    }

    /**
     * @param {any} body Un object contenant 1 paramètre : Un objet représentant un nouveau cloud
     * Crée un cloud
     * @returns {Observable} Un observable d'un booléen si la création du cloud s'est déroulé correctement
     */
    @log() createCloud(body: any): Observable<any> {
        return this.http.post('/reservations', body).pipe(
            tap({
                next: () => this.flashMessageService.flash("L'espace cloud a bien été créé")
            })
        );
    }

    /**
     * @param {number} cloudId L'identifiant du cloud que l'on souhaite mettre à jour
     * @param {any} body Un object contenant 1 paramètre : Un objet représentant un cloud existant
     * Met à jour un cloud
     * @returns {Observable} Un observable d'un booléen si la mise à jour du cloud s'est déroulé correctement
     */
    @log() updateCloud(cloudId: number, body: any): Observable<any> {
        return this.http.put('/reservations/' + cloudId, body).pipe(
            tap({
                next: () => this.flashMessageService.flash("L'espace cloud a bien été mis à jour")
            })
        );
    }

    /**
     * @param {number} id L'identifiant du cloud à récuperer
     * Supprime un cloud
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression s'est déroulé correctement
     */
    @log() deleteCloud(id: number): Observable<any> {
        return this.http.get('/reservations/delete/' + id).pipe(
            tap({
                next: () => this.flashMessageService.flash("L'espace cloud a bien été supprimé")
            })
        );
    }

    /**
     * @param {number} id L'identifiant du cloud à masquer
     * Supprime un cloud
     * @returns {Observable} Un observable d'un booléen indiquant si le masquage s'est déroulé correctement
     */
    @log() maskCloud(id: number): Observable<any> {
        return this.http.post('/reservations/setVisibility/' + id, { visibility: false });
    }

    /**
     * @param {number} id L'identifiant du cloud à démasquer
     * Supprime un cloud
     * @returns {Observable} Un observable d'un booléen indiquant si le démasquage s'est déroulé correctement
     */
    @log() unmaskCloud(id: number): Observable<any> {
        return this.http.post('/reservations/setVisibility/' + id, { visibility: true });
    }

    @log() toggleMuteCloud(cloud: Cloud): Observable<any> {
        return this.http.get(`/reservations/${cloud.id}/${cloud.isMuted ? 'unmute' : 'mute'}`);
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour la liste des clouds
     */
    refreshClouds() {
        this.refresh.next('');
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour la liste des clouds
     */
    refreshCloud(cloudId: number) {
        this.refreshSingleCloud.next(cloudId);
    }

    emitClearCurrentCloud() {
        this.clearCurrentCloud.next();
    }
}
