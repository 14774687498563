<div class="header">
    <div class="title">Masquer un espace</div>
</div>
<mat-dialog-content class="content">
    Vous vous apprêtez à masquer un espace.<br />
    Il ne sera plus visible dans <b>Mes espaces</b> mais restera disponible dans la section
    <b>Espaces masqués</b>.<br />
    Vous pouvez rétablir cet espace à tout moment à partir de la section Espaces masqués.
</mat-dialog-content>
<div class="footer">
    <div class="confirm button" (click)="maskCloud()">ok</div>
    <div class="cancel button" (click)="closeDialog()">ANNULER</div>
</div>
