<div class="header">
    <span class="icon-Warning"></span>
    <span class="label">Suppression d'un espace</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    Vous vous apprêtez à supprimer un espace.<br />
    Il ne sera plus visible et les fichiers qu'il contient seront supprimés
    <b>définitivement</b>.<br />
    <strong>Veillez à sauvegarder</strong> auparavant les fichiers qui pourraient vous être encore
    utiles.
    <div class="confirm-delete-container">
        <div class="confirm-delete-label">
            Tapez "<b>suppression définitive</b>" dans le champ pour confirmer
        </div>
        <input class="confirm-delete-input" [(ngModel)]="deleteInput" />
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="delete-button" [ngClass]="{ disabled: !canDelete() }" (click)="deleteCloud()">
        <span *ngIf="!setWaiting">Supprimer l'espace</span>
        <div class="loadingEntry" *ngIf="setWaiting">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </div>
</div>
