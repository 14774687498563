<div class="container" [ngClass]="{ unselected: !isElementSelected() }">
    <div
        class="select-box"
        (click)="toggleSelected()"
        [ngClass]="{ 'icon-select': !isElementSelected(), 'icon-selected': isElementSelected() }"
    ></div>
    <div class="icon" [ngClass]="getIconStyle()"></div>
    <div class="name">{{ getElementName() }}</div>
    <div class="structure" *ngIf="canShowStructure()">
        <lib-uimm-dropdown
            [(items)]="structures"
            (itemsChange)="updateStructure($event)"
            [multiselect]="true"
            [label]="'Structures'"
            [labelWidth]="'190px'"
            [maxWidth]="400"
            [maxHeight]="200"
            [design]="'boxes'"
            [headers]="{ search: true, selectAll: true }"
            [customClass]="'right'"
        ></lib-uimm-dropdown>
    </div>
    <div class="role" *ngIf="isElementSelected()">
        <span
            class="icon icon-droit-lecture"
            *ngIf="isReader()"
            tooltip="Lecture seule"
            tooltipPosition="left"
            tooltipColor="#e8b600"
            (click)="toggleRole()"
        ></span>
        <span
            class="icon icon-droit-ecriture"
            *ngIf="isWriter()"
            tooltip="Lecture / Ecriture"
            tooltipPosition="left"
            tooltipColor="#e8b600"
            (click)="toggleRole()"
        ></span>
        <span
            class="icon icon-droit-moderation"
            *ngIf="isModerator()"
            tooltip="Modérateur"
            tooltipPosition="left"
            tooltipColor="#e8b600"
            (click)="toggleRole()"
        ></span>
    </div>
    <div
        class="icon-down"
        [ngClass]="{ opened: isGroupOpened() }"
        *ngIf="type === 'group'"
        (click)="showGroup()"
    ></div>
    <div
        class="group-container"
        *ngIf="type === 'group'"
        [@slideToggle]="openedGroup ? 'open' : 'closed'"
    >
        <div class="name-container" *ngFor="let user of element.users">
            <span [ngClass]="getUserIcon(user)"></span>
            <span class="name">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span>
        </div>
    </div>
</div>
