import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
/**
 * Service gérant l'affichage des messages de succès/erreur lors des appels aux webservice
 */
@Injectable({
    providedIn: 'root'
})
export class LogService {
    constructor(private configService: ConfigService) {}

    /**
     * Affiche les données récupérés par un webservice en cas de succès de la requete
     * @param {String} origin Le couple Service-Fonction à l'origine de l'appel au service de log
     * @param {String} data Les données renvoyés par le webservice
     */
    success(origin: string, data: any) {
        if (this.configService.getConfig().debugSuccess) {
            console.log(`%c${origin} :`, 'color: #e8b600', data);
        }
    }

    /**
     * Affiche les données récupérés par un webservice en cas d'erreur lors de la requete
     * @param {String} origin Le couple Service-Fonction à l'origine de l'appel au service de log
     * @param {String} data Les données renvoyés par le webservice
     */
    warn(origin: string, warn: any) {
        if (this.configService.getConfig().debugWarn) {
            console.warn(`${origin} : ${JSON.stringify(warn)}`);
        }
    }

    /**
     * Affiche les données récupérés par un webservice en cas d'erreur lors de la requete
     * @param {String} origin Le couple Service-Fonction à l'origine de l'appel au service de log
     * @param {String} data Les données renvoyés par le webservice
     */
    error(origin: string, error: any) {
        if (this.configService.getConfig().debugError) {
            console.error(`${origin} :`, error);
        }
    }
}
