import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EasiHttpParams } from './easi-http-params';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
    constructor() {}

    /**
     * Ajoute withCredentails à tous les requêtes.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!(request.params instanceof EasiHttpParams) || !request.params.noCredentials) {
            request = request.clone({
                withCredentials: true
            });
        }
        return next.handle(request);
    }
}
